<template>
  <div style="position: relative;">
    <div style="width:26%;float:left;">
      <el-input v-model.trim="listQuery.name" placeholder="请输入存货名称" style="width: 150px;" size="small" clearable @keyup.enter="getList"/>
      <el-button size="small" type="primary" @click="getList" class="mr-5" icon="Search">搜索</el-button>
      <search @success="request" @cancel="cancel">
        <el-form style="width:580px" label-width="100px" class="styleForm" :inline="true">
          <el-form-item label="会计期间："> 
            <qzf-period v-model:period="listQuery.periodSt" :placeholder="'会计账期起'" style="width: 110px;"></qzf-period>
            <span>-</span>
            <qzf-period v-model:period="listQuery.periodEd" :placeholder="'会计账期止'" style="width: 110px;"></qzf-period>
          </el-form-item>
          <el-form-item label="存货类型："> 
            <selectInventoryType v-model:type="listQuery.type" style="width: 150px;"></selectInventoryType>
          </el-form-item>
          <el-form-item label="金额区间：">
              <el-input placeholder="金额起" v-model="listQuery.beginMoney" style="width: 73px;" size="small" />-
              <el-input placeholder="金额止" v-model="listQuery.endMoney" style="width: 73px;" size="small" />
            </el-form-item>
            <el-form-item label="数量区间：">
              <el-input placeholder="数量起" v-model="listQuery.benginCount" style="width: 73px;" size="small" />-
              <el-input placeholder="数量止" v-model="listQuery.endCount" style="width: 73px;" size="small" />
            </el-form-item>
          <el-form-item label="存货为0："> 
            <el-radio v-model="listQuery.not0" :label="true">不显示</el-radio>
            <el-radio v-model="listQuery.not0" :label="false">显示</el-radio>
          </el-form-item>
          <el-form-item label="期末库存为0：" label-width="110px"> 
            <el-radio v-model="listQuery.qmkc0" :label="true">不显示</el-radio>
            <el-radio v-model="listQuery.qmkc0" :label="false">显示</el-radio>
          </el-form-item>
          <el-form-item label="期末余额为0："> 
            <el-radio v-model="listQuery.qmje0" :label="true">不显示</el-radio>
            <el-radio v-model="listQuery.qmje0" :label="false">显示</el-radio>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div style="width:40%;text-align:right;float:right">
      <qzf-button :disabled="!$buttonStatus('ch_kchzb_sdrk')" jz="true" @success="inventoryStockIn" size="small">
        <el-icon><SortDown /></el-icon><span  > 手动入库</span>
      </qzf-button>
      <qzf-button :disabled="!$buttonStatus('ch_kchzb_sdck')" jz="true" @success="inventoryStockOut" size="small">
        <el-icon><SortUp /></el-icon> <span  > 手动出库</span>
      </qzf-button>
      <el-dropdown size="small" style="margin-left:10px">
        <el-button size="small" type="primary">
          更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="f">
              <el-tooltip
                class="item"
                effect="dark"
                content="同步本年数据 "
                placement="top"
                v-if="!this.tongbu"
              >
                <div jz="false" button_code="zb_kmye_tbkm" @click="tongbuNormal()" type="primary" size="small" icon="Connection">同步</div>
              </el-tooltip>
            </el-dropdown-item>
            <el-dropdown-item command="d">
              <div @click="costSet" v-if="$buttonStatus('ch_kchzb_cbtc')">成本调差</div>
            </el-dropdown-item>
            <el-dropdown-item command="g">
              <div @click="manualOperation">手动出入库</div>
            </el-dropdown-item>
            <el-dropdown-item command="e">
              <div @click="codingSort">一键生成编号</div>
            </el-dropdown-item>
            <el-dropdown-item command="b">
              <div @click="exportInventory">导出</div>
            </el-dropdown-item>
            <el-dropdown-item command="c">
              <div @click="singleDayin">打印</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div :style="{ height: this.contentStyleObj, paddingTop: '34px' }">
      <vxe-table
        ref="vxeTableInstance"
        v-loading="loading"
        :data="tableData"
        :scroll-y="{enabled: true, gt: 0,}"
        height="auto"
        style="width: 100%;"
        border
        stripe
        auto-resize
        size="mini"
        :show-footer="!!tableData.length"
        :footer-method="getFooterDataTable"
        footerRowClassName="inventory-period-table-footer"
        @sort-change="sortChange"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{resizable: true}"
      >
      <!-- <el-table class="tableClassIn" stripe border :data="list" style="width: 100%" show-summary :summary-method="getSummaries" row-key="id" :height="contentStyleObj" v-loading="loading" @select="handleSelectionChange" ref="tableScrollPeriod" id="tableLazyLoadPeriod" @select-all="handleSelectionChangeAll" @sort-change="sortChange"> -->
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column fixed="left" field="sortColumn" type="checkbox" align="center" width="60" />
        <vxe-column fixed="left" field="coding" title="编号" width="70" align="center" sortable>
          <template #default="{ row }">
            <span @click="saveSort(row)" style="color: orange;cursor: pointer;">{{ sortNumber(row.coding)}}</span>
          </template>
        </vxe-column>
        <vxe-column fixed="left" field="inventoryName2" show-overflow title="别名" width="200" />
        <vxe-column fixed="left" field="inventoryName" show-overflow title="名称" width="180">
          <template #default="{ row }">
            <span @click="details(row)" style="cursor: pointer;text-decoration: underline;">{{ row.inventoryName}}</span>
          </template>
        </vxe-column>
        <vxe-column fixed="left" field="inventorySpec" title="型号" width="180" />
        <vxe-column align="center" field="inventoryUnit" title="单位" width="150" />
        <vxe-column align="center" field="inventoryType" title="存货类型" width="150">
          <template #default="{ row }">
            <span>{{$inventoryTypeFilter(row.inventoryType)}}</span>
          </template>
        </vxe-column>

        <vxe-colgroup field="endAmountCountHeader" title="期末库存" header-align='center'>
          <vxe-column field="endCount" title="数量" align="center" width="120" />
          <vxe-column title="平均单价" field="endAmountCount" align="center" width="120">
            <template #default="{ row }">
              <span v-if="row.endCount">{{ calAvePrice(row.endAmount/row.endCount) }}</span>
              <span v-else>0</span>
            </template>
          </vxe-column>
          <vxe-column field="endAmount" title="金额" align="center" width="120" />
        </vxe-colgroup>

        <vxe-colgroup field="inAmountCountHeader" title="本期入库" header-align='center'>
          <vxe-column field="inCount" title="数量" align="center" width="120">
          </vxe-column>
          <vxe-column title="平均单价" field="inAmountCount" align="center" width="120">
            <template #default="{ row }">
              <span v-if="row.inCount">{{ calAvePrice(row.inAmount/row.inCount) }}</span>
              <span v-else>0</span>
            </template>
          </vxe-column>
          <vxe-column field="inAmount" title="金额" align="center" width="120">
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup field="estimateAmountCountHeader" title="暂估入库" header-align='center'>
          <vxe-column field="estimateCount" title="数量" align="center" width="120">
          </vxe-column>
          <vxe-column title="平均单价" field="estimateAmountCount" align="center" width="120">
            <template #default="{ row }">
              <span v-if="row.estimateCount ">{{ calAvePrice(row.estimateAmount/row.estimateCount) }}</span>
              <span v-else>0</span>
            </template>
          </vxe-column>
          <vxe-column field="estimateAmount" title="金额" align="center" width="120">
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup field="estimateOutAmountCountHeader" title="暂估出库" header-align='center'>
          <vxe-column field="estimateOutCount" title="数量" align="center" width="120">
          </vxe-column>
          <vxe-column title="平均单价" field="estimateOutAmountCount" align="center" width="120">
            <template #default="{ row }">
              <span v-if="row.estimateOutCount ">{{ calAvePrice(row.estimateOutAmount/row.estimateOutCount) }}</span>
              <span v-else>0</span>
            </template>
          </vxe-column>
          <vxe-column field="estimateOutAmount" title="金额" align="center" width="120">
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup field="outAmountCountHeader" title="本期出库" header-align='center'>
          <vxe-column field="outCount" title="数量" align="center" width="120">
          </vxe-column>
          <vxe-column title="平均单价" field="outAmountCount" align="center" width="120">
            <template #default="{ row }">
              <span v-if="row.outCount">{{ calAvePrice(row.outAmount/row.outCount) }}</span>
              <span v-else>0</span>
            </template>
          </vxe-column>
          <vxe-column field="outAmount" title="金额" align="center" width="120">
          </vxe-column>
        </vxe-colgroup>

        <vxe-colgroup title="期初库存" field="openingInventory" header-align='center'>
          <vxe-column field="beginCount" title="数量" align="center" width="120">
          </vxe-column>
          <vxe-column title="平均单价" field="beginAmountCount" align="center" width="120">
            <template #default="{ row }">
              <span v-if="row.beginCount ">{{ calAvePrice(row.beginAmount/row.beginCount) }}</span>
              <span v-else>0</span>
            </template>
          </vxe-column>
          <vxe-column field="beginAmount" title="金额" align="center" width="120">
          </vxe-column>
        </vxe-colgroup>
      </vxe-table>
    </div>
      
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" :pageSizes="[10, 20, 50, 100, 200 ,300,500,1000]"/>
    </div>

    <!-- 出入库明细 -->
    <el-dialog :close-on-click-modal="false"
      v-model="detaileVisible"
      title="出入库明细"
      width="60%"
      destroy-on-close
    >
      <vxe-table
        ref="vxeTable"
        v-loading="detailLoading"
        :data="detailTable"
        :scroll-y="{enabled: true, gt: 0,}"
        height="300"
        style="width: 100%;"
        border
        stripe
        auto-resize
        size="mini"
        :column-config="{resizable: true}"
      >
      <!-- <el-table stripe :data="list" style="width: 100%" border size="small" :height="contentStyleObj" @select="handleSelectionChange" show-summary :summary-method="getSummaries" ref="tableScrollDetail" id="tableLazyLoadDetail" @select-all="handleSelectionChangeAll"> -->
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="sortColumn" type="checkbox" width="50"> </vxe-column>
        <vxe-column field="inventoryName" title="名称" width="180"> 
          <template #default="scope">
            <span>{{scope.row.inventoryName}}</span>
          </template>
        </vxe-column>
        <vxe-column field="inventoryName2" title="别名" min-width="180" />
        <vxe-column field="spec" align="center" title="型号" min-width="100" />
        <vxe-column field="time" align="center" prop="time" title="日期" min-width="100">
          <template #default="scope">
            <span>{{$parseTime(scope.row.time, "{y}-{m}-{d}")}}</span>
          </template>
        </vxe-column>
        <vxe-column field="type" align="center"  title="出入库类型" min-width="180" show-overflow-tooltip>
          <template #default="scope">
            <span>{{$inventoryCategoryFilter(scope.row.type)}}</span>
          </template>
        </vxe-column>
        <vxe-column field="inventoryType" align="center"  title="存货类型" min-width="180">
          <template #default="scope">
            <span>{{$inventoryTypeFilter(scope.row.inventoryType)}}</span>
          </template>
        </vxe-column>
        <vxe-column field="unit" align="center" title="单位" min-width="80" />
        <vxe-column field="voucherNo" align="center" title="关联凭证" min-width="80"> 
          <template #default="scope">
            <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
          </template>
        </vxe-column>
        <vxe-column align="center" field="count" title="数量" min-width="130" />
        <vxe-column align="center" field="amount" title="金额" min-width="150" />
      </vxe-table>
      <template #footer>
        <span class="dialog-footer" style="margin-top: 15px;">
        </span>
      </template>
      <div class="pagination">
        <qzf-pagination v-show="detalilTotal>0" :total="detalilTotal" v-model:page="detailListQuery.page" v-model:limit="detailListQuery.limit" @pagination="getDetailList" />
      </div>
    </el-dialog>
    <inventoryStock @success="getList()" ref="inventoryStockIn" name="手动入库"></inventoryStock>
    <inventoryStock @success="getList()" ref="inventoryStockOut" name="手动出库"></inventoryStock>

    <printVouch ref="printVouch" name="inventoryPeriod"  :listQuery1="this.listQuery"></printVouch>
    <costAdjust ref="costAdjust" />
    <manulStock ref="manulStock" />
  </div>
</template>

<script>
import { inventoryPeriodList,editInventoryCoding,oneKeyProduceCoding,inventoryDetailList } from '@/api/inventory'
import { inventorySummarySheet } from "@/api/export"
import inventoryStock from "./inventoryStock.vue"
import selectInventoryType from "./selectInventoryType.vue"
import { linkAgeAll } from '@/api/carryover'

import printVouch from "../../../bookkeeps/components/printVouch.vue"
import costAdjust from "./costAdjust.vue"
import manulStock from './manulStock.vue'
export default {
  name:'inventoryPeriod',
  components:{
    inventoryStock,
    selectInventoryType,
    printVouch,
    costAdjust,
    manulStock
  },
  data() {
    return {
      detailLoading:false,
      detaileVisible:false,
      detalilTotal:0,
      detailTable:[],
      detailListQuery:{
        limit:20,
        page:1,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        inventoryId:null,
      },
      list:[],
      sumList:[],
      listQuery: {
        subjectCode: '',
        name: '',
        change: 0,
        page: 1,
        limit: 20,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        not0:false,
        qmkc0:false,
        qmje0:false,
        beginMoney:'',
        endMoney:'',
        benginCount:'',
        endCount:''
      },
      total:0,
      loading:false,
      comId: this.$store.getters['user/comInfo'].comId*1,
      totalPage:0,
      pageSize:35,
      tableData:[],
      currentPage:1,
      allChecked:false,
      sels:[],
      footerDataTable: [],
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(304)
  },
  methods:{
    calAvePrice(num) {
      return (Math.abs(num)).toFixed2()
    },
    getFooterDataTable({ columns }) {
      if (!columns) return
      const sumKey = ['endCount', 'endAmount', 'inCount', 'inAmount', 'estimateCount', 'estimateAmount', 'estimateOutCount', 'estimateOutAmount', 'outCount', 'outAmount', 'beginCount', 'beginAmount',]
      const footerData = columns.map((item, i) => {
        if (i === 0) {
          return '合计'
        }
        if (sumKey.includes(item.field)) {
          return this.sumList[item.field]
        }
        return ''
      })
      return [footerData]
    },
    getList(){
      this.loading = true
      inventoryPeriodList(this.listQuery).then(res=>{
        this.loading = false
        this.sumList = res.data.data.sum
        this.tableData = res.data.data.list || []
        this.total = res.data.data.total
      })
    },
    request(){
      if (this.listQuery.beginMoney==''&&this.listQuery.endMoney!=''||this.listQuery.beginMoney!=''&&this.listQuery.endMoney=='') {
        this.$qzfMessage("请完整填写金额区间", 1);
      }else if (this.listQuery.benginCount==''&&this.listQuery.endCount!=''||this.listQuery.benginCount!=''&&this.listQuery.endCount=='') {
        this.$qzfMessage("请完整填写数量区间", 1);
      }else if (this.listQuery.periodSt==''&&this.listQuery.periodEd!=''||this.listQuery.periodSt!=''&&this.listQuery.periodEd=='') {
        this.$qzfMessage("请完整填写会计期间", 1);
      }else{
        this.getList();
      }
    },
    inventoryStockIn(){
      this.$refs.inventoryStockIn.init()
    },
    inventoryStockOut(){
      this.$refs.inventoryStockOut.init()
    },
    handleSelectionChange(data) {
      this.sels = data.records
    },
    handleSelectionChangeAll(data){
      this.sels = data.records
    },
    singleDayin(){
      let ids = []
      this.sels.map(v=>{
        ids.push(v.inventoryId)
      })
      this.$refs.printVouch.init([this.comId],this.listQuery.period,ids)
    },
    exportInventory(){
      let arr = []
      this.sels.map(v=>{
        arr.push(v.inventoryId)
      })
      let param = {
        bookNames:['inventoryPeriod'],
        type:'pdf',
        query:this.listQuery,
        ids:arr
      }
      inventorySummarySheet(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    ///成本调差
    costSet(){
      this.$refs.costAdjust.init()
    },
    //同步正常账期科目余额表
    tongbuNormal(){
      this.$confirm('确认同步科目余额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        linkAgeAll({}).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("同步成功");
            this.getList()
          }
        })
      })
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        subjectCode: '',
        name: '',
        change: 0,
        page: 1,
        limit: originLimit,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        not0:false,
        qmkc0:false,
        qmje0:false,
        beginMoney:'',
        endMoney:'',
        benginCount:'',
        endCount:'',
        ordercoding:0
      }
      this.getList()
    },
    sortNumber(e){
      if(!e){
        return "默认"
      }else{
        return e
      }
    },
    saveSort(row){
      this.$prompt('请输入存货编号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:'',
        beforeClose:(action,instance,done)=>{
          if(action == 'confirm'){
            let value = instance.inputValue;
            if(isNaN(Number(value))|| value*1>99999 || value*1<1){
              this.$qzfMessage('请输入范围在1-99999内的数字',1)
            }else{
              editInventoryCoding({inventoryId: row.inventoryId,coding: value*1}).then(res=>{
                done();
                if(res.data.msg == 'success'){
                  this.$qzfMessage("保存成功")
                  this.getList()
                  this.$bus.emit('inventoryEstimatedUpdate')
                  this.$bus.emit('inventoryManageUpdate')
                }
              })
            }
          }else{
            done()
          }
        }
      })
    },
    codingSort(){
      oneKeyProduceCoding({}).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('生成成功')
          this.getList()
          this.$bus.emit('inventoryEstimatedUpdate')
        }
      })
    },
     // 表格排序
     sortChange(data) {
      if(data.order == "desc"){
        this.listQuery.ordercoding = 1
      }else{
        this.listQuery.ordercoding = 0
      }
      this.getList()
    },
    //手动出入库
    manualOperation(){
      this.$refs.manulStock.init()
    },
    // 点击名称出入库明细
    details(val){
      this.detailListQuery.inventoryId = val.inventoryId
      this.getDetailList()
    },
    getDetailList(){
      this.detailLoading = true
      inventoryDetailList(this.detailListQuery).then(res=>{
        this.detailTable = res.data.data.list || []
        this.detalilTotal = res.data.data.total
        if(this.detalilTotal > 0){
          this.detaileVisible = true
        }else{
          this.$qzfMessage("无出入库明细", 1);
        }
        this.detailLoading = false
      })
    },
    // 关联凭证
    gotoSubjectItem(row){
      let voucherId = row.voucherId
      let ops = this.$store.getters['commons/periods'];
      let lll = this.xx(row.period,ops)
      this.$store.dispatch('commons/getSubjects', this.comId)
      if(lll != "正常账期"){
        this.$store.dispatch('commons/setParam', {historyVoucherId: voucherId,hisVoucherPeriod: row.period})
        this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
        this.$router.push({
          path: "/bookkeeps/components/historyVoucher",
          name: "historyVoucher",
        });
      }else{
        this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
      }
      this.detaileVisible = false
    },
    xx(period, list){
      let s = ""
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        if(element.label != '初始账期'){
          for (let index = 0; index < element.options.length; index++) {
            let element2 = element.options[index];
            if(element2.period == period){
              s = element.label
            }
          }
        } 
      }
      return s
    },
  }
}
</script>

<style>

</style>

<style scoped lang="scss">
.el-select{
  margin-left:0px
}
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 275px);
  border:1px solid #e6e6e6;
  position: relative;
}
:deep(.el-dropdown-menu__item){
  display: block;
}
:deep(.vxe-table--footer) {
  // width: 690px !important;
  width:30% !important;
}
</style>

<style lang="scss">
/* ::-webkit-scrollbar {
    width: 1px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
    background: rgba(17, 16, 16, 0.13);
    -webkit-box-shadow: rgba(0, 0, 0, 0.9);
    box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #efefef;
} */
.tableClassIn{
  .is-last-column{
    border-right: 1px solid #e0d6d6 !important;
  }
}

</style>
<template>
  <div style="position: relative;">
    <div style="float:left;">
      <search @success="getList" @cancel="cancel">
        <el-form style="margin:11px 0px 0px -26px;width:380px" label-width="120px" class="styleForm">
          <el-form-item label="存货名称 ：">          
            <el-input placeholder="请输入名称或编号" style="width: 200px;" size="small" v-model.trim="listQuery.name" clearable/>
          </el-form-item>
          <!-- <el-form-item label="账期 ：">   
            <qzf-period v-model:period="listQuery.period" style="width: 200px;"></qzf-period>
          </el-form-item> -->
          <el-form-item label="所属期:">
            <qzf-period
              v-model="listQuery.startPeriod"
              type="month"
              placeholder="选择账期"
              value-format="YYYYMM"
              style="width: 91px;">
            </qzf-period> -- 
            <qzf-period
              v-model="listQuery.endPeriod"
              type="month"
              placeholder="选择账期"
              value-format="YYYYMM"
              style="width: 91px;">
            </qzf-period>
          </el-form-item>
          <el-form-item label="是否有入库 ：">   
            <el-select v-model="listQuery.cx" placeholder="请选择是否有入库" size="small" style="width: 200px;">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="有入库" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="冲销状态 ：">   
            <el-select v-model="listQuery.status" placeholder="冲销状态" size="small" style="width: 200px;">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="已冲销" :value="1"></el-option>
              <el-option label="未冲销" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div style="text-align:right;float:right">
      <el-popconfirm
        confirm-button-text="确认"
        cancel-button-text="取消"
        icon="InfoFilled"
        icon-color="red"
        title="是否确认自动暂估回冲？"
        @confirm="autoBack"
      >
        <template #reference>
          <el-button type="success" size="small">
            <img style="margin-left:-7px;margin-top:2px;width:21px;height:14px;margin-right:3px" src="@/assets/ai3d.png" alt="">
            自动暂估回冲
          </el-button>
        </template>
      </el-popconfirm>

      <qzf-button :disabled="!$buttonStatus('ch_zgrk_cx')" size="small" type="primary" @success="writeOff"   >
        <el-icon><List /></el-icon><span  > 批量冲销</span>
      </qzf-button>
      <qzf-button :disabled="!$buttonStatus('ch_zgrk_zgrk')" @success="inventoryStockEsti" size="small" >
        <el-icon><SortDown /></el-icon><span  > 暂估入库</span>
      </qzf-button>
      <!-- <settings :disabled="!$buttonStatus('ch_zgrk_zgkmsz')" name="暂估科目设置" :type="['code_inventory_zg']"></settings> -->
      <qzf-button @success="delAll" size="small" type="danger" plain :loading="delLoading">
        <el-icon><Delete /></el-icon><span  > 删除</span>
      </qzf-button>
      <div style="text-align:right;float:right">
        <el-dropdown size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="b">
                <span @click="daoru">导入</span>
              </el-dropdown-item>
              <el-dropdown-item command="c">
                <span @click="daochu">导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div :style="{ height: this.contentStyleObj, paddingTop: '34px' }">
      <vxe-table
        ref="vxeTableInstance"
        v-loading="loading"
        :data="tableData"
        :scroll-y="{enabled: true, gt: 0,}"
        height="auto"
        style="width: 100%;"
        border
        stripe
        auto-resize
        size="mini"
        :show-footer="!!tableData.length"
        :footer-method="getFooterDataTable"
        @sort-change="sortChange"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{resizable: true}"
      >
    <!-- <el-table stripe :data="list" style="width: 100%" @select="handleSelectionChange" border :height="contentStyleObj" show-summary :summary-method="getSummaries" ref="tableScrollEstimate" id="tableLazyLoadEstimate" @select-all="handleSelectionChangeAll" @sort-change="sortChange" v-loading="loading" > -->
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="sortColumn" type="checkbox" width="55"></vxe-column>
        <vxe-column field="sort" title="编号" width="70" align="center" sortable="custom">
          <template #default="scope">
            <span style="color: orange;">{{ sortNumber(scope.row.coding)}}</span>
          </template>
        </vxe-column>
        <vxe-column field="createdAt" align="center" title="生成日期" min-width="120">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}")}}</span>
          </template>
        </vxe-column>
        <vxe-column field="time" align="center" title="暂估日期" min-width="120">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.time, "{y}-{m}-{d}")}}</span> 
          </template>
        </vxe-column>
        <vxe-column field="period" align="center" title="暂估账期" min-width="120" />
        <vxe-column field="inventoryType" align="center" title="类别" min-width="120">
          <template #default="scope">
            <span>{{$inventoryTypeFilter(scope.row.inventoryType)}}</span> 
          </template>
        </vxe-column>
      
        <vxe-column field="name" title="名称" min-width="120" />
        <vxe-column field="spec" align="center" title="型号" min-width="120" />
        <vxe-column field="unit" align="center" title="单位" min-width="50" />
        <vxe-column align="center" title="数量" min-width="100" field="count" />
        <vxe-column field="amountCount" align="center" title="单价" min-width="120" >
          <template #default="scope">
            <span>{{ scope.row.count ? (scope.row.amount *1/scope.row.count*1).toFixed(2) : scope.row.amount }}</span> 
          </template>
        </vxe-column>
        <vxe-column align="center" title="金额" min-width="120" field="amount" />
        <vxe-column align="center" title="已冲销数量" min-width="120" field="backCount" />
        <vxe-column align="center" title="已冲销金额" min-width="120" field="backAmount" />
        <vxe-column align="center" title="未冲销数量" min-width="120" field="weiCount">
          <template #default="scope">
            <span>{{ (scope.row.count - scope.row.backCount).toFixed(3) }}</span> 
          </template>
        </vxe-column>
        <vxe-column align="center" title="未冲销金额" min-width="120" field="weiAmount">
          <template #default="scope"> 
            <span>{{ (scope.row.amount - scope.row.backAmount).toFixed(2) }}</span> 
          </template>
        </vxe-column>
        <vxe-column field="backwashStatus" align="center" title="是否有入库" width="90">
          <template #default="scope"> 
            <span v-if="scope.row.cx && scope.row.backwashStatus == 0 && periodSelected > scope.row.period" style="color: red;">有入库</span> 
          </template>
        </vxe-column>
        <vxe-column field="operation" align="center" title="操作" width="150">
          <template #default="scope">
            <qzf-button :disabled="!$buttonStatus('ch_zgrk_cx')" v-if="scope.row.backwashStatus == 0 && periodSelected > scope.row.period" size="small" type='text' @success="handleChongXiao(scope.row)">冲销</qzf-button>
            <span v-if="scope.row.backwashStatus != 0">已冲销</span>
            <qzf-button v-if="!scope.row.voucherId && scope.row.backwashStatus == 0" size="small" type='text' @success="del(scope.row)" style="color:#fe696a">删除</qzf-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="错误提示"
      v-model="dialogCallVisible"
      width="500px"
    >
      <div style="max-height: 450px;overflow: auto;" >
        <p v-for="(item,index) in callList" :key="index" style="margin-bottom: 10px;">{{ item }}</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogCallVisible = false">
            关闭
          </el-button>
        </span>
      </template>
    </el-dialog>
    <inventoryWriteOff ref="inventoryWriteOff" @success="getList()"></inventoryWriteOff>
    <inventoryStock ref="inventoryStockEsti" @success="getList()" name="暂估入库"></inventoryStock>
    <qzf-import-back @success2="getCallback" ref="importBck" from="暂估入库" :hideButton="true" :comId="listQuery.comId" @success="getList()"></qzf-import-back>
  </div>
</template>

<script>
import { estimateList, delImportEstimate,estimateAutoHc } from '@/api/inventory'
import { estimateSheet } from '@/api/export'
import inventoryStock from "./inventoryStock.vue"
import inventoryWriteOff from "./inventoryWriteOff.vue"
import Search from '../../../../components/search/search.vue'
export default {
  name:'inventoryEstimated',
  components:{
    inventoryStock,
    inventoryWriteOff,
    Search
  },
  data() {
    return {
      list:[],
      listQuery: {
        name: "",
        period: "",
        status: 0,
        comId:this.$store.getters['user/comInfo'].comId*1,
        page:1,
        limit:20,
        startPeriod: "",
        endPeriod: "",
      },
      periodSelected: "",
      select:[],
      periodList:[],
      callList:[],
      dialogCallVisible:false,
      totalPage:0,
      pageSize:35,
      tableData:[],
      currentPage:1,
      allChecked:false,
      total:0,
      loading:false,
      delLoading:false,
      footerDataTable: [],
    }
  },
  created() {
    this.getList()
    this.periodSelected = this.$store.getters['user/comInfo'].period
    this.contentStyleObj= this.$getHeight(304)
  },
  methods:{
    getFooterDataTable({ columns, data }) {
      const sumKey = ['amount', 'count', 'backCount', 'backAmount', 'weiCount', 'weiAmount']
      const footerData = columns.map((item, i) => {
        if (i === 0) {
          return '合计'
        }
        if (sumKey.includes(item.field)) {
          return data.reduce((acc, d) => {
            let num = d[item.field] || 0
            if(item.field === 'count'){
              acc = +((acc + (num)).toFixed(3))
            }else{
              if (item.field === 'weiCount') {
                num = d.count - d.backCount
              }
              if (item.field === 'weiAmount') {
                num = d.amount - d.backAmount
              }
              acc = +((acc + (num)).toFixed(2))
            }
            return acc
          }, 0)
        }
        return ''
      })
      return [footerData]
    },
    writeOff(){
      // 批量冲销 考虑账期 todo
      let array = []
      let statusCx = false
      let statusCx2 = false
      this.select.map(item => {
        if(this.periodSelected <= item.period){
          statusCx2 = true
        }
        if(item.backwashStatus != 0){
          statusCx = true
        }
        array.push({
          id:item.id*1,
          name: item.name,
          backAmount: (item.amount * 1 - item.backAmount * 1).toFixed(2) * 1,
          backCount: (item.count * 1 - item.backCount * 1).toFixed(6) * 1,
        });
      });
      if(statusCx){
        this.$qzfMessage("不可重复冲销",2)
        return
      }
      if(statusCx2){
        this.$message.error('本月不可冲销本月及本月之后暂估的');
        return
      }
      if(array.length == 0){
        this.$message.error('请至少选择一条')
        return
      }
      this.$refs.inventoryWriteOff.getList(array)
    },
    getList() {
      if((this.listQuery.startPeriod != "" && this.listQuery.endPeriod == "") || (this.listQuery.startPeriod == "" && this.listQuery.endPeriod != "")){
        this.$message.error('所属期选择错误')
        return
      }
      estimateList(this.listQuery).then(res=>{
        this.tableData = res.data.data.list || []
        this.total = res.data.data.total
      })
    },
    handleChongXiao(item){
      let tempItem = {
        id: item.id,
        name:item.name,
        backAmount: (item.amount * 1 - item.backAmount * 1).toFixed(2) * 1,
        backCount: (item.count * 1 - item.backCount * 1).toFixed(6) * 1,
      }
      this.$refs.inventoryWriteOff.getList([tempItem])
    },
    // 多选 批量冲销
    handleSelectionChange(data){
      this.select = data.records
    },
    handleSelectionChangeAll(data){
      this.select = data.records
    },
    //暂估入库
    inventoryStockEsti(){
      this.$refs.inventoryStockEsti.init()
    },
    //导出
    daochu(){
      let param = {
        query:this.listQuery,
        ids:[]
      };
      this.select.map(v=>{
        param.ids.push(v.id)
      })
      estimateSheet(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    getSummaries(param){
      let { columns, data } = param;
      let amount = 0;
      let count = 0;
      let backCount = 0;
      let backAmount = 0;
      let weiCount = 0;
      let weiAmount = 0;
      data = this.tableData;
      data.map(v=>{
        amount += Number(v.amount)
        count += Number(v.count)
        backCount += Number(v.backCount)
        backAmount += Number(v.backAmount)
        weiCount += (Number(v.count) - Number(v.backCount))
        weiAmount += (Number(v.amount) - Number(v.backAmount))
      })
      const sums = [];
      columns.forEach((column, index)=>{
        if (index === 0) {
          sums[index] = '合计';
          return;
        }else if(column.property == "amount"){
          sums[index] = this.$comdify(amount.toFixed(2))
        }else if(column.property == "count"){
          sums[index] = this.$comdify(count.toFixed(2))
        }else if(column.property == "backCount"){
          sums[index] = this.$comdify(backCount.toFixed(2))
        }else if(column.property == "backAmount"){
          sums[index] = this.$comdify(backAmount.toFixed(2))
        }else if(column.property == "weiCount"){
          sums[index] = this.$comdify(weiCount.toFixed(2))
        }else if(column.property == "weiAmount"){
          sums[index] = this.$comdify(weiAmount.toFixed(2))
        }
      })
      return sums;
    },
    //导入
    daoru(){
      this.$refs.importBck.handImport()
    },
    getCallback(item){
      this.callList = item.errs
      this.dialogCallVisible = true
    },
    //删除
    delAll(){
      if(this.select.length == 0){
        this.$qzfMessage('至少选择一条',1)
        return
      }
      let str = ''
      this.select.map(v=>{
        if(v.voucherId){
          str = v.name + '已关联凭证，不可删除'
          return
        }
        if(v.backwashStatus){
          str = v.name + '已冲销，不可删除'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      this.$confirm(`确认删除${this.select.length}条数据么? `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delLoading = true
        delImportEstimate(this.select).then(res=>{
          this.delLoading = false
          if(res.data.msg == 'success'){
            this.$qzfMessage('成功删除')
            this.getList()
          }
        })
      })
    },
    del(row){
      this.$confirm(`确认删除么? `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delImportEstimate([row]).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('成功删除')
            this.getList()
          }
        })
      })
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        name: "",
        period: "",
        status: 0,
        comId:this.$store.getters['user/comInfo'].comId*1,
        page:1,
        limit:originLimit,
        ordercoding:0,
        startPeriod: "",
        endPeriod: "",
      }
      this.getList()
    },
    //编号
    sortNumber(e){
      if(!e){
        return "默认"
      }else{
        return e
      }
    },
      // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.ordercoding = 1
      }else{
        this.listQuery.ordercoding = 0
      }
      this.getList()
    }, 

    // 自动暂估回冲
    autoBack(){
      this.loading = true
      estimateAutoHc({}).then(res => {
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("回冲成功")
          this.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
 
.el-select{
  margin-left:0px
}
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 275px);
  border:1px solid #e6e6e6;
  position: relative;
}
</style>
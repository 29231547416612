<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="停止服务原因选择"
    width="400px"
    destroy-on-close
  >
    <el-scrollbar :maxHeight="300">
      <el-radio-group v-model="lossReason">
        <el-radio
          :value="item.name"
          v-for="item in options"
          :label="item.name"
          :key="item.name"
        ></el-radio>
        <el-radio :value="reasonInput" :label="reasonInput">
          <el-input
            v-model="reasonInput"
            size="small"
            style="width: 200px"
          ></el-input>
        </el-radio>
      </el-radio-group>
    </el-scrollbar>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="editSure"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { totalStopCustomer, lossList } from "@/api/newCrm";
const emit = defineEmits();
const dialogFormVisible = ref(false);
const options = ref([]);
const reasonInput = ref(null);
const lossReason = ref("");
const ids = ref([]);
const init = (item) => {
  reasonInput.value = null;
  lossReason.value = "";
  getLoss();
  ids.value = item.map((v) => v.id);
  dialogFormVisible.value = true;
};
const editSure = () => {
  let text = lossReason.value ? lossReason.value : reasonInput.value;
  if (!text) {
    ElMessage.warning("请选择或输入流失原因");
    return;
  }
  totalStopCustomer({
    ids: ids.value,
    reasonLoss: text,
    customerType: "3",
  }).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("操作成功");
      dialogFormVisible.value = false;
      emit("success");
    }
  });
};
const getLoss = () => {
  lossList({}).then((res) => {
    if (res.data.msg == "success") {
      options.value = res.data.data;
    }
  });
};
defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.el-radio,
.el-radio-group {
  display: block;
}
</style>

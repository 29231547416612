<template>
  <el-drawer v-model="drawer" :close-on-click-modal="false" size="850px">
    <template #header>
      <div class="header_top">
        <div>新增</div>
        <div>
          <el-button type="primary" @click="save" size="small">保存</el-button>
        </div>
      </div>
    </template>
    <el-form
      :model="form"
      label-width="118px"
      :rules="rules"
      size="small"
      ref="ruleFormRef"
      :inline="true"
    >
      <div class="record">基本信息：</div>
      <el-form-item label="客户名称" prop="customerName">
        <el-input
          v-model="form.customerName"
          placeholder="请输入客户名称"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户编码" prop="customerNum">
        <el-input
          v-model="form.customerNum"
          placeholder="请输入客户编码"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="纳税人资格" prop="taxType">
        <selecttaxtype
          v-model:type="form.taxType"
          class="w250 margin0"
        ></selecttaxtype>
      </el-form-item>
      <el-form-item label="服务类型" prop="server">
        <el-checkbox-group v-model="form.server">
          <el-checkbox label="代账" value="代账" />
          <el-checkbox label="增值" value="增值" />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="客户联系人" prop="linkman">
        <el-input
          v-model="form.linkman"
          placeholder="请输入客户联系人"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人手机" prop="linkmanTel">
        <el-input
          v-model="form.linkmanTel"
          placeholder="请输入联系人手机"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="老板手机号" prop="bossTel">
        <el-input
          v-model="form.bossTel"
          placeholder="请输入老板手机号"
          class="w250"
        ></el-input>
      </el-form-item>
      <div class="record">工商信息：</div>
      <el-form-item label="统一社会信用代码" prop="taxNo">
        <el-input
          v-model="form.taxNo"
          placeholder="请输入统一社会信用代码"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="法定代表人" prop="manager">
        <el-input
          v-model="form.manager"
          placeholder="请输入法定代表人"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="注册资本" prop="regNumber">
        <el-input
          v-model="form.regNumber"
          placeholder="请输入注册资本"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="住所" prop="address">
        <el-input
          v-model="form.address"
          placeholder="请输入住所"
          class="w250"
        ></el-input>
      </el-form-item>
      <el-form-item label="经营范围" prop="jyfw">
        <el-input
          v-model="form.jyfw"
          placeholder="请输入经营范围"
          type="textarea"
          class="w650"
        ></el-input>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script setup>
import { ref, defineExpose } from "vue";
import { ElMessage } from "element-plus";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { saveCustomer } from "@/api/newCrm";
let validateMobile = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入手机号！"));
  }
  if (!/^1[3456789]\d{9}$/.test(value)) {
    callback(new Error("请输入正确的手机号！"));
  } else {
    return callback();
  }
};
const emit = defineEmits();
const drawer = ref(false);
const form = ref({
  customerType: "2",
});
const rules = ref({
  customerName: [
    { required: true, message: "请输入客户名称", trigger: "blur" },
  ],
  server: [{ required: true, message: "请选择服务类型", trigger: "change" }],
  linkmenTel: [
    { required: true, message: "请输入联系人手机号", trigger: "blur" },
  ],
  bossTel: [{ required: true, validator: validateMobile, trigger: "blur" }],
});
const ruleFormRef = ref();
const init = (row) => {
  drawer.value = true;
  reset();
  if (row) {
    form.value = Object.assign({}, row);
    form.value.server = row.serverType.split(",");
  }
};
const reset = () => {
  form.value = {
    customerType: "2",
  };
};
const save = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      form.value.serverType = form.value.server.join(",");
      saveCustomer(form.value).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("保存成功");
          drawer.value = false;
          emit("success");
        }
      });
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.w250 {
  width: 250px !important;
}
.margin0 {
  margin: 0 !important;
}
.w650 {
  width: 650px !important;
}
</style>

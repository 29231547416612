<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="分配"
    width="950px"
    destroy-on-close
    custom-class="dialog_style"
  >
    <el-icon class="close_icon" @click="dialogFormVisible = false"
      ><Close
    /></el-icon>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="分配权限" name="1">
        <el-scrollbar :height="380">
          <el-form :model="form">
            <el-form-item label="人员">
              <selectuser
                code="bookkeeping"
                v-model:userId="form.distributionId"
                class="w300"
              ></selectuser>
            </el-form-item>
          </el-form>
        </el-scrollbar>
        <div class="dialog_footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button size="small" type="primary" @click="editSure"
            >确定</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="权限查看" name="2">
        <el-table
          :data="list"
          style="width: 100%"
          border
          :height="400"
          v-loading="loading"
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column
            type="index"
            label="序号"
            width="55"
            align="center"
          />
          <el-table-column label="客户编号" prop="customerNum" width="90">
          </el-table-column>
          <el-table-column label="客户名称" prop="customerName" min-width="140">
          </el-table-column>
          <el-table-column label="部门" prop="deptName" min-width="100">
          </el-table-column>
          <el-table-column label="姓名" prop="cnName" min-width="100">
          </el-table-column>
          <el-table-column label="角色" prop="roleName" min-width="100">
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template #default="scope">
              <el-button
                link
                size="small"
                @click="redeploy(scope.row)"
                icon="Switch"
                >转派</el-button
              >
              <el-button
                link
                size="small"
                @click="del(scope.row)"
                icon="Delete"
                type="danger"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogZpVisible"
    title="转派"
    width="300px"
    destroy-on-close
  >
    <el-form :model="temp">
      <el-form-item label="转派人员">
        <select-common
          placeholder="请选择转派人员"
          v-model:modelValue="temp.newUserId"
          :options="props.userOptions"
          valueKey="id"
          labelKey="CnName"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogZpVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveAllot()"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineEmits, defineExpose } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  totalDistributionCustomer,
  distributionCustomerList,
  delDistributionCustomer,
} from "@/api/newCrm";
const props = defineProps({
  userOptions: {
    type: Array,
  },
});
const emit = defineEmits();
const dialogFormVisible = ref(false);
const dialogZpVisible = ref(false);
const form = ref({
  ids: [],
  distributionId: [],
});
const activeName = ref("1");
const list = ref([]);
const loading = ref(false);
const init = (item) => {
  reset();
  form.value.ids = item.map((v) => v.id);
  dialogFormVisible.value = true;
};
const temp = ref({});
const editSure = () => {
  if (form.value.distributionId.length == 0) {
    ElMessage.warning("请选择人员");
  } else {
    totalDistributionCustomer(form.value).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("分配成功");
        dialogFormVisible.value = false;
        emit("success");
      }
    });
  }
};
const getList = () => {
  loading.value = true;
  distributionCustomerList({ ids: form.value.ids }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
    }
  });
};
const handleClick = (tab) => {
  activeName.value = tab.props.name;
  if (tab.props.name == "2") {
    getList();
  }
};
const del = (row) => {
  ElMessageBox.confirm("确定要删除此人员吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    let param = {
      id: row.customerId,
      oldUserId: row.userId,
      newUserId: 0,
    };
    delDistributionCustomer(param).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("操作成功");
        getList();
      }
    });
  });
};
const redeploy = (row) => {
  temp.value.id = row.customerId;
  temp.value.oldUserId = row.userId;
  temp.value.newUserId = null;
  dialogZpVisible.value = true;
};
const saveAllot = () => {
  if (!temp.value.newUserId) {
    ElMessage.warning("请选择转派人");
    return;
  }
  delDistributionCustomer(temp.value).then((res) => {
    if (res.data.msg == "success") {
      dialogZpVisible.value = false;
      ElMessage.success("操作成功");
      getList();
    }
  });
};
const reset = () => {
  form.value = {
    ids: [],
    distributionId: [],
  };
};
defineExpose({
  init,
});
</script>
<style>
.dialog_style .el-dialog__header {
  display: none !important;
}
.dialog_style .el-dialog__body {
  padding: 0px 20px 10px !important;
}
</style>
<style lang="scss" scoped>
.w300 {
  width: 300px !important;
}
.close_icon {
  position: absolute;
  top: 14px;
  right: 27px;
  cursor: pointer;
  z-index: 99;
}
.dialog_footer {
  text-align: right;
}
</style>
